<template>
  <div>
    <hotels-component />
    <footer-component />
  </div>
</template>

<script>
import HotelsComponent from "@/components/hotels/HotelsComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "Hotels",
  title: "Top 10 Hotels | Baja California Health Tourism",
  components: {
    FooterComponent,
    HotelsComponent,
  },
  mounted() {
    changeInformationSEO();
  }
};
</script>

<style scoped></style>
